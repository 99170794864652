// import React, { useEffect, useState } from "react";
// import { Box, ButtonGroup, Button, Flex, Text, Stack, Image } from "@chakra-ui/react";
// import style from "../../Style/Submit.module.css";
// import { useToast, Card,  CardHeader, Avatar, Heading , IconButton  } from "@chakra-ui/react";
// import { useSelector } from "react-redux";
// import axios from "axios";
// import notification from "../Toast";
// import { Form1 } from "./Form1";
// import { Form2 } from "./Form2";
// import { Form3 } from "./Form3";
// import { Form4 } from "./Form4";


// // userID:

// // tool_url:
// // dasboard_link:

// // tool_name:
// // tagline:

// // description:
// // key_feature:
// // social_media:
// // categories:
// // integration:
// // other:
// // price:
// // price_amount:

// // promo_code:
// // offer:
// // expire_date:

// // thumbnail:
// // cover_image:
// // gallery:
// // youtube_url:
// // tool_own:
// // verify:

// export default function Multistep({ setOpen, updateData, id }) {

//   const [underCategories, setUnderCategories] = useState([]);


//   const [step, setStep] = useState(1);


//   const [works_with, setworks_with] = useState([]);
//   const [Category, setCategory] = useState([]);
//   const [others_features, setothers_features] = useState([]);
//   const [Logo, setLogo] = useState("");
//   const [Cover_image, setCover_image] = useState("");
//   const [Galary_image, setGalary_image] = useState([]);
//   const [tool_own, setTool] = useState(false);
  
//   const [social_media, setSocial_media] = useState([]);
//   const [Tags, setTag] = useState([]);
//   const [key_features, setkey_features] = useState([]);
//   // const { loginData } = useSelector((state) => state.userReducer);

//   const [data, setData] = useState({
//     URL: "",
//     Title: "",
//     Tagline: "",
//     Description: "",
//     Pricing: "",
//     price_amount: "",
//     Youtube_embed: "",
//     afilitedLink: "",
//     Support:"",
//     isCSVFile: false,
//     Discount:""
//   });

//   const userData = useSelector((state) => state.userReducer.loginData);
//   const handlechange = (e) => {
//     setData({
//       ...data,
//       [e.target.name]: e.target.value,
//     });
//   };
//   let payload = {
//     ...data,
//     works_with,
//     Category,
//     others_features,
//     Logo,
//     Cover_image,
//     Galary_image,
//     tool_own,
//     social_media,
//     Tags,
//     key_features,
//   };




//   const getCat = async () => {
//     try {
//       const res = await axios.get(`${process.env.REACT_APP_API}/cat/get`);
      
//       // Create an array of all category names
//       let categoryNames = res.data.flatMap(categoryDoc => categoryDoc.Category);
  
//       // Filter out duplicates by converting to a Set and back to an array
//       categoryNames = [...new Set(categoryNames)];
  
//       // Map to the desired format for react-select
//       let categoriesOptions = categoryNames.map(cat => ({ value: cat, label: cat }));
  
//       // Sort categories alphabetically by label
//       categoriesOptions.sort((a, b) => a.label.localeCompare(b.label));
  
//       setUnderCategories(categoriesOptions);
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   };
  
  
//   useEffect(() => {
//     getCat();
//   }, []); // Empty dependency array ensures this runs once on mount
  


//   useEffect(()=> {
//     const interval = ()=>  {return setTimeout(() => {
//       // console.log(updateData);
//       if (updateData && Object.keys(updateData).length > 0) {
//         setworks_with(updateData.works_with)
//         setCategory(updateData.Category)
//         setothers_features(updateData.others_features)
//         setLogo(updateData.Logo)
//         setCover_image(updateData.Cover_image)
//         setGalary_image(updateData.Galary_image)
//         setTool(updateData.tool_own)
//         setSocial_media(updateData.social_media)
//         setTag(updateData.Tags)
//         setkey_features(updateData.key_features)
//         let data = {
//           // URL: updateData.URL,
//           Title: updateData.Title,
//           Tagline: updateData.Tagline,
//           Description: updateData.Description,
//           Pricing: updateData.Pricing,
//           price_amount: updateData.price_amount,
//           Youtube_embed: updateData.Youtube_embed,
//           afilitedLink: updateData.afilitedLink,
//           Support: updateData.SupSupportport,
//           isCSVFile: updateData.isCSVFile,
//           Discount: updateData.Discount
//         }
//         setData(data)
//         // clearInterval(interval())
//       } else {
//         interval()
//       }
//     }, 2000)};
//     interval()
//   }, [updateData])

//   useEffect(() => {
//     getCat();
    
//   }, []);


//   const validateForm = () => {
//     // Safely check if strings are defined and trimmed
//     const safeTrim = (str) => (str || '').trim();
  
//     // Validation for Form1
//     if (!
//       updateData && !safeTrim(data.URL)) {
//       notification("error", "URL of the tool is required.");
//       return false;
//       }
//       if (!safeTrim(data.Support)) {
//         notification("error", "Please add your email ID");
//         return false;
//       }
//     if (!safeTrim(data.Title)) {
//       notification("error", "Name of the tool is required.");
//       return false;
//     }
//     if (!safeTrim(data.Tagline)) {
//       notification("error", "Tagline is required.");
//       return false;
//     }
//     if (!safeTrim(data.Description)) {
//       notification("error", "Description is required.");
//       return false;
//     }
//     // if (key_features.every(feature => !safeTrim(feature))) {
//     //   notification("error", "At least one key feature is required.");
//     //   return false;
//     // }
//     if (Category.length === 0) {
//       notification("error", "At least one category is required.");
//       return false;
//     }
  
//     // Validation for Form2
//     if (!data.Pricing) {
//       notification("error", "Price option is required.");
//       return false;
//     }
  
//     // Validation for Form3
//     if (!safeTrim(Logo)) {
//       notification("error", "Thumbnail/logo is required.");
//       return false;
//     }
//     if (!safeTrim(Cover_image)) {
//       notification("error", "Cover image is required.");
//       return false;
//     }
  
//     return true;
//   };
  


//   const handleSubmitTool = async () => {
//     if (!validateForm()) return;
//     console.log("payload", payload)
//     try {
//       const token = userData.data;
//       if (token) {
//         axios
//           .post(`${process.env.REACT_APP_API}/submit/add`, payload, {
//             headers: { token },
//           })
//           .then((res) => {
//             setOpen();
//             notification("success", res?.data?.msg);
//           });
//       } else {
//         notification("error", "Login First!");
//       }
//     } catch (error) {
//       console.log("error", error)
//     }
//   };

//   const updateTool = async () => {
//     if (!validateForm()) return;
//     console.log("payload", payload);
//     try {
//       const headers = {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//         // "user-Agent": "Thunder Client(https://www.thunderclient.com/)",
//         token: userData.data,
//       };
//       const token = userData.data;
//       // ${process.env.REACT_APP_API}
//       if (token) {
//         axios
//           .patch(`${process.env.REACT_APP_API}/reqAccess/update/${id}`, payload, {headers})
//           .then((res) =>{
//             console.log(res);
//               notification("success", "Updated successfully!");
//               setTimeout(() =>{
//                 window.location.reload();
//               }, 1000)
//           }).catch((error) =>{
//             notification("error", error?.response?.data?.error)
//           })
            
  
//       } else {
//         notification("error", "Login First!");
//       }
//     } catch (error) {
      
//       console.log(error);
//     }
//   };

//   return (
//     <Box className={style.submitform} mt="20px">
//       <Stack className={style.left}>
//         <Box w="100%" fontWeight="600">


//         {updateData && <Card maxW='md' bg={"transparent"} boxShadow={'none'} borderColor={'transparent'}>
//           <CardHeader>
//             <Flex spacing='4'>
//               <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
//                 {/* <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' /> */}
//                 <Image
//                   boxSize='50px'
//                   objectFit='cover'
//                   src={updateData.Logo}
//                   alt='Dan Abramov'
//                 />
//                 <Box>
//                   <Heading size='md'>{updateData.Title}</Heading>
//                   <Text style={{
//                       display: "inline-block",
//                       width: "250px",
//                       whiteSpace: "nowrap",
//                       overflow: "hidden",
//                       textOverflow: "ellipsis",
//                       fontSize:".8rem"
//                   }}>{updateData.Tagline}</Text>
//                 </Box>
//               </Flex>
//             </Flex>
//           </CardHeader>      

          
//         </Card>}


//           <Text
//             mb="10px"
//             w="fit-content"
//             onClick={() => {
//               setStep(1);
//             }}
//             cursor="pointer"
//             p="5px"
//             borderRadius="3px"
//             color={step === 1 ? "white" : ""}
//             bg={step == 1 ? "#3B89B6" : ""}
//           >
//             Basic Information
//           </Text>
//           <Text
//             mb="10px"
//             w="fit-content"
//             onClick={() => {
//               setStep(2);
//             }}
//             cursor="pointer"
//             p="5px"
//             borderRadius="3px"
//             color={step == 2 ? "white" : ""}
//             bg={step == 2 ? "#3B89B6" : ""}
//           >
//             Price & deal
//           </Text>
//           <Text
//             mb="10px"
//             w="fit-content"
//             onClick={() => {
//               setStep(3);
//             }}
//             cursor="pointer"
//             p="5px"
//             borderRadius="3px"
//             color={step === 3 ? "white" : ""}
//             bg={step == 3 ? "#3B89B6" : ""}
//           >
//             Media
//           </Text>
//           {console.log(updateData)}
//           {(!updateData) && <Text
//             mb="10px"
//             w="fit-content"
//             onClick={() => {
//               setStep(4);
//             }}
//             cursor="pointer"
//             p="5px"
//             borderRadius="3px"
//             color={step === 4 ? "white" : ""}
//             bg={step === 4 ? "#3B89B6" : ""}
//           >
//             Finish
//           </Text>}
//         </Box>
//       </Stack>
//       <Stack className={style.right} fontSize="20px" fontWeight="400">
//         <Box className={style.leftborder} w="100%">
//           {step === 1 ? (
//             <Form1
//               handlechange={handlechange}
//               works_with={works_with}
           

//               others_features={others_features}
//               Category={Category}
//               {...data}
//               key_features={key_features}
//               setkey_features={setkey_features}
//               setCategory={setCategory}
//               social_media={social_media}
//               setSocial_media={setSocial_media}
             
//               setworks_with={setworks_with}
//               setothers_features={setothers_features}
//               underCategory={underCategories}
              
//             />
//           ) : step === 2 ? (
//             <Form2
//               handlechange={handlechange}
//               Pricing={data.Pricing} 
//               {...data}
//               Tags={Tags}
//               setTag={setTag}
//               setData={setData} // Add this line
//               data={data} // And this line
//             />
//           ) : step === 3 ? (
//             <Form3
//               setCover_image={setCover_image}
//               setGalary_image={setGalary_image}
//               setLogo={setLogo}
//               Logo={Logo}
//               Galary_image={Galary_image}
//               Cover_image={Cover_image}
//               handlechange={handlechange}
//               {...data}
//             />
//           ) : (
//             <Form4 tool_own={tool_own} setTool={setTool} />
//           )}
//           <ButtonGroup mt="5%" w="100%">
//             <Flex w="100%" justifyContent="space-between">
//               {!(step === 3 && updateData) && <Box w="100%" textAlign="right">
//                 <Button
//                   w="68px"
//                   mt="10px"
//                   color="white"
//                   borderRadius="4px"
//                   bg="#3B89B6"
//                   _hover={{ bg: "" }}
//                   display={step === 4 ? "none" : ""}
//                   onClick={() => {
//                     setStep(step + 1);
//                   }}
//                 >
//                   Next
//                 </Button>
//               </Box>}
//               {(step === 4 || (step === 3 &&  updateData)) ? (
//                 <Button
//                   w="193px"
//                   height="28px"
//                   mt="10px"
//                   color="white"
//                   _hover={{ bg: "" }}
//                   borderRadius="4px"
//                   bg="#3B89B6"
//                   onClick={updateData ? updateTool : handleSubmitTool}
//                 >
//                   {(step === 3 &&  updateData) ? "Save" : "Submit"}
//                 </Button>
//               ) : null}
//             </Flex>
//           </ButtonGroup>
//         </Box>
//       </Stack>
//     </Box>
//   );
// }


import React, { useEffect, useState } from "react";
import { Box, ButtonGroup, Button, Flex, Text, Stack, Image } from "@chakra-ui/react";
import style from "../../Style/Submit.module.css";
import { useToast, Card,  CardHeader, Avatar, Heading , IconButton  } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import axios from "axios";
import notification from "../Toast";
import { PricingForm } from "./Pricingform";
import { Form1 } from "./Form1";
import { Form2 } from "./Form2";
import { Form3 } from "./Form3";
import { Form4 } from "./Form4";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

// userID:

// tool_url:
// dasboard_link:

// tool_name:
// tagline:

// description:
// key_feature:
// social_media:
// categories:
// integration:
// other:
// price:
// price_amount:

// promo_code:
// offer:
// expire_date:

// thumbnail:
// cover_image:
// gallery:
// youtube_url:
// tool_own:
// verify:

export default function Multistep({ setOpen, updateData, id }) {

  const [underCategories, setUnderCategories] = useState([]);


  const [step, setStep] = useState(0);


  const [works_with, setworks_with] = useState([]);
  const [Category, setCategory] = useState([]);
  const [others_features, setothers_features] = useState([]);
  const [Logo, setLogo] = useState("");
  const [Cover_image, setCover_image] = useState("");
  const [Galary_image, setGalary_image] = useState([]);
  const [tool_own, setTool] = useState(false);
  
  const [social_media, setSocial_media] = useState([]);
  const [Tags, setTag] = useState([]);
  const [key_features, setkey_features] = useState([]);
  // const { loginData } = useSelector((state) => state.userReducer);

  const [amount, setAmount] = useState("22.00"); // Base amount
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [couponApplied, setCouponApplied] = useState(false);


  const [data, setData] = useState({
    URL: "",
    Title: "",
    Tagline: "",
    Description: "",
    Pricing: "",
    price_amount: "",
    Youtube_embed: "",
    afilitedLink: "",
    Support:"",
    isCSVFile: false,
    Discount:""
  });

  const userData = useSelector((state) => state.userReducer.loginData);
  const handlechange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  let payload = {
    ...data,
    works_with,
    Category,
    others_features,
    Logo,
    Cover_image,
    Galary_image,
    tool_own,
    social_media,
    Tags,
    key_features,
  };




  const getCat = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/cat/get`);
      
      // Create an array of all category names
      let categoryNames = res.data.flatMap(categoryDoc => categoryDoc.Category);
  
      // Filter out duplicates by converting to a Set and back to an array
      categoryNames = [...new Set(categoryNames)];
  
      // Map to the desired format for react-select
      let categoriesOptions = categoryNames.map(cat => ({ value: cat, label: cat }));
  
      // Sort categories alphabetically by label
      categoriesOptions.sort((a, b) => a.label.localeCompare(b.label));
  
      setUnderCategories(categoriesOptions);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  
  
  useEffect(() => {
    getCat();
  }, []); // Empty dependency array ensures this runs once on mount
  


  useEffect(()=> {
    const interval = ()=>  {return setTimeout(() => {
      // console.log(updateData);
      if (updateData && Object.keys(updateData).length > 0) {
        setworks_with(updateData.works_with)
        setCategory(updateData.Category)
        setothers_features(updateData.others_features)
        setLogo(updateData.Logo)
        setCover_image(updateData.Cover_image)
        setGalary_image(updateData.Galary_image)
        setTool(updateData.tool_own)
        setSocial_media(updateData.social_media)
        setTag(updateData.Tags)
        setkey_features(updateData.key_features)
        let data = {
          // URL: updateData.URL,
          Title: updateData.Title,
          Tagline: updateData.Tagline,
          Description: updateData.Description,
          Pricing: updateData.Pricing,
          price_amount: updateData.price_amount,
          Youtube_embed: updateData.Youtube_embed,
          afilitedLink: updateData.afilitedLink,
          Support: updateData.SupSupportport,
          isCSVFile: updateData.isCSVFile,
          Discount: updateData.Discount
        }
        setData(data)
        // clearInterval(interval())
      } else {
        interval()
      }
    }, 2000)};
    interval()
  }, [updateData])

  useEffect(() => {
    getCat();
    
  }, []);


  const validateForm = () => {
    // Safely check if strings are defined and trimmed
    const safeTrim = (str) => (str || '').trim();
  
    // Validation for Form1
    if (!
      updateData && !safeTrim(data.URL)) {
      notification("error", "URL of the tool is required.");
      return false;
      }
      if (!safeTrim(data.Support)) {
        notification("error", "Please add your email ID");
        return false;
      }
    if (!safeTrim(data.Title)) {
      notification("error", "Name of the tool is required.");
      return false;
    }
    if (!safeTrim(data.Tagline)) {
      notification("error", "Tagline is required.");
      return false;
    }
    if (!safeTrim(data.Description)) {
      notification("error", "Description is required.");
      return false;
    }
    // if (key_features.every(feature => !safeTrim(feature))) {
    //   notification("error", "At least one key feature is required.");
    //   return false;
    // }
    if (Category.length === 0) {
      notification("error", "At least one category is required.");
      return false;
    }
  
    // Validation for Form2
    if (!data.Pricing) {
      notification("error", "Price option is required.");
      return false;
    }
  
    // Validation for Form3
    if (!safeTrim(Logo)) {
      notification("error", "Thumbnail/logo is required.");
      return false;
    }
    if (!safeTrim(Cover_image)) {
      notification("error", "Cover image is required.");
      return false;
    }
  
    return true;
  };
  
  const [showPayPal, setShowPayPal] = useState(false);

  //////// just add this part to remove exclusive email feature
  // const handleSubmitTool = async () => {
  //   if (!validateForm()) return;
  //   setShowPayPal(true);
  // };
/////////// till here

///////// from here the exclusive email feature start
  const handleSubmitTool = async () => {
    if (!validateForm()) return;
    
    const bypassEmails = ['hello@aizones.io', 'marketing@heyreal.ai'];
    if (bypassEmails.includes(userData.email)) {
      await submitToolWithoutPayment();
    } else {
      setShowPayPal(true);
    }
  };

  const submitToolWithoutPayment = async () => {
    try {
      const token = userData.data;
      if (token) {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/submit/add`,
          payload,
          { headers: { token } }
        );
        setOpen();
        notification("success", response?.data?.msg);
      } else {
        notification("error", "Login First!");
      }
    } catch (error) {
      console.log("error", error);
      notification("error", "An error occurred while submitting the tool.");
    }
  };
  /////////// till here

  const handlePaymentSuccess = async (details) => {
    try {
      const token = userData.data;
      if (token) {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/submit/add`,
          { ...payload, paymentDetails: details },
          { headers: { token } }
        );
        setOpen();
        notification("success", response?.data?.msg);
        setShowPayPal(false);
      } else {
        notification("error", "Login First!");
      }
    } catch (error) {
      console.log("error", error);
      notification("error", "An error occurred while submitting the tool.");
    }
  };

  const handleApplyCoupon = () => {
    // Convert both the coupon code and the user input to lowercase for a case-insensitive comparison
    if (couponCode.toLowerCase() === "aidirectories50") {
      const discountedPrice = (22.00 * 0.5).toFixed(2); // 50% discount
      setAmount(discountedPrice); // Update amount to $11.00
      notification("success", "50% discount applied successfully!");
    } else {
      notification("error", "Invalid coupon code");
    }
  };

  const updateTool = async () => {
    if (!validateForm()) return;
    console.log("payload", payload);
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "user-Agent": "Thunder Client(https://www.thunderclient.com/)",
        token: userData.data,
      };
      const token = userData.data;
      // ${process.env.REACT_APP_API}
      if (token) {
        axios
          .patch(`${process.env.REACT_APP_API}/reqAccess/update/${id}`, payload, {headers})
          .then((res) =>{
            console.log(res);
              notification("success", "Updated successfully!");
              setTimeout(() =>{
                window.location.reload();
              }, 1000)
          }).catch((error) =>{
            notification("error", error?.response?.data?.error)
          })
            
  
      } else {
        notification("error", "Login First!");
      }
    } catch (error) {
      
      console.log(error);
    }
  };

  return (
    //<PayPalScriptProvider options={{ "client-id": "ASeJaRjiB5qX3YuDHT7wvtKDctpXqOPFkFliYC6RsxWEuhMpFSkwg6soXZtQp_mtmdU1Kxbk2GY9EGtq" }}> sandbox 
    <PayPalScriptProvider options={{ "client-id": "AV1A4Nh3Q314GJBlcc7lL1hKe8FU-KA5HekPKdVS2_utNyjGHGGYjATYyQ06IFXZhYspC8CtvO9tmtqa" }}>
      <Box className={style.submitform} mt="20px">
        <Stack className={style.left}>
          <Box w="100%" fontWeight="600">


        {updateData && <Card maxW='md' bg={"transparent"} boxShadow={'none'} borderColor={'transparent'}>
          <CardHeader>
            <Flex spacing='4'>
              <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                {/* <Avatar name='Segun Adebayo' src='https://bit.ly/sage-adebayo' /> */}
                <Image
                  boxSize='50px'
                  objectFit='cover'
                  src={updateData.Logo}
                  alt='Dan Abramov'
                />
                <Box>
                  <Heading size='md'>{updateData.Title}</Heading>
                  <Text style={{
                      display: "inline-block",
                      width: "250px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize:".8rem"
                  }}>{updateData.Tagline}</Text>
                </Box>
              </Flex>
            </Flex>
          </CardHeader>      

          
        </Card>}

        {console.log(updateData)}
       {(!updateData) && 
            <Text
            mb="10px"
            w="fit-content"
            onClick={() => {
              setStep(0);
            }}
            cursor="pointer"
            p="5px"
            borderRadius="3px"
            color={step === 0 ? "white" : ""}
            bg={step == 0 ? "#3B89B6" : ""}
          >
            Submission fee
          </Text>
           }


          <Text
            mb="10px"
            w="fit-content"
            onClick={() => {
              setStep(1);
            }}
            cursor="pointer"
            p="5px"
            borderRadius="3px"
            color={step === 1 ? "white" : ""}
            bg={step == 1 ? "#3B89B6" : ""}
          >
            Basic Information
          </Text>
          <Text
            mb="10px"
            w="fit-content"
            onClick={() => {
              setStep(2);
            }}
            cursor="pointer"
            p="5px"
            borderRadius="3px"
            color={step == 2 ? "white" : ""}
            bg={step == 2 ? "#3B89B6" : ""}
          >
            Price & deal
          </Text>
          <Text
            mb="10px"
            w="fit-content"
            onClick={() => {
              setStep(3);
            }}
            cursor="pointer"
            p="5px"
            borderRadius="3px"
            color={step === 3 ? "white" : ""}
            bg={step == 3 ? "#3B89B6" : ""}
          >
            Media
          </Text>
          {console.log(updateData)}
          {(!updateData) && <Text
            mb="10px"
            w="fit-content"
            onClick={() => {
              setStep(4);
            }}
            cursor="pointer"
            p="5px"
            borderRadius="3px"
            color={step === 4 ? "white" : ""}
            bg={step === 4 ? "#3B89B6" : ""}
          >
            Finish
          </Text>}
          </Box>
        </Stack>
        <Stack className={style.right} fontSize="20px" fontWeight="400">
          <Box className={style.leftborder} w="100%">
        {step === 0 ? ( <PricingForm  tool_own={tool_own} setTool={setTool}/>)
           :step === 1 ? (
            <Form1
              handlechange={handlechange}
              works_with={works_with}
           

              others_features={others_features}
              Category={Category}
              {...data}
              key_features={key_features}
              setkey_features={setkey_features}
              setCategory={setCategory}
              social_media={social_media}
              setSocial_media={setSocial_media}
             
              setworks_with={setworks_with}
              setothers_features={setothers_features}
              underCategory={underCategories}
              
            />
            ) : step === 2 ? (
            <Form2
              handlechange={handlechange}
              Pricing={data.Pricing} 
              {...data}
              Tags={Tags}
              setTag={setTag}
              setData={setData} // Add this line
              data={data} // And this line
            />
            ) : step === 3 ? (
            <Form3
              setCover_image={setCover_image}
              setGalary_image={setGalary_image}
              setLogo={setLogo}
              Logo={Logo}
              Galary_image={Galary_image}
              Cover_image={Cover_image}
              handlechange={handlechange}
              {...data}
            />
            ) : (
              <Form4 tool_own={tool_own} setTool={setTool} />
            )}
            <ButtonGroup mt="5%" w="100%">
              <Flex w="100%" justifyContent="space-between">
              {!(step === 3 && updateData) && <Box w="100%" textAlign="right">
                    <Button
                      w="68px"
                      mt="10px"
                      color="white"
                      borderRadius="4px"
                      bg="#3B89B6"
                      _hover={{ bg: "" }}
                      display={step === 4 ? "none" : ""}
                      onClick={() => {
                        setStep(step + 1);
                      }}
                    >
                      Next
                    </Button>
                  </Box>
                }
                {step === 4 ? (
                  <Box>
                    {!showPayPal ? (
                      <>
                        <Box>
                          <input
                            type="text"
                        
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                            placeholder="Enter coupon code"
                            style={{padding: "8px",
                              border: "1px solid #ccc", // Adding border to the input box
                              borderRadius: "4px",
                              marginRight: "10px",
                              height:"35px"}}
                          />
                          <Button onClick={handleApplyCoupon}>Apply Coupon</Button>
                        </Box>
                        <Button
                          w="193px"
                          // height="28px"
                          mt="10px"
                          color="white"
                          _hover={{ bg: "" }}
                          borderRadius="4px"
                          bg="#3B89B6"
                          onClick={handleSubmitTool}
                          style={{padding: "8px",
                            border: "1px solid #ccc", // Adding border to the input box
                            borderRadius: "4px",
                            marginRight: "10px"}}
                        >
                          Pay & Submit
                        </Button>
                      </>
                    ) : (
                      <PayPalButtons
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: amount, // Use the updated amount after applying coupon
                                },
                              },
                            ],
                          });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order.capture().then((details) => {
                            handlePaymentSuccess(details);
                          });
                        }}
                        onError={(err) => {
                          console.error(err);
                          notification("error", "Payment was not successful. Please try again.");
                        }}
                      />
                    )}
                  </Box>
                ) : null}
                {step === 3 && updateData && (
                  <Button
                    w="193px"
                    height="28px"
                    mt="10px"
                    color="white"
                    _hover={{ bg: "" }}
                    borderRadius="4px"
                    bg="#3B89B6"
                    onClick={updateTool}
                  >
                    Save
                  </Button>
                )}
              </Flex>
            </ButtonGroup>
          </Box>
        </Stack>
      </Box>
    </PayPalScriptProvider>
  );
}


