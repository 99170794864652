import React, { useEffect, useState, useRef } from "react";
import { BsChatText, BsGrid } from "react-icons/bs";
import { MdOutlineCategory } from "react-icons/md";
import { ImList2 } from "react-icons/im";
import style from "../../Style/Searchcomp.module.css";
import style2 from "../../Style/Landing.module.css";
import { FaHome } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { CgMenuGridO } from "react-icons/cg";
import { Spinner } from '@chakra-ui/react'; // Import Spinner from Chakra UI

import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  IconButton,
  InputRightElement,
  Menu,
  Stack,
  MenuButton,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Divider,
  ModalHeader,
  ModalOverlay,
  Text,
  Select,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FilterSearch } from "./FilterSearch";

import { LandingPage } from "../../Pages/LandingPage";

import axios from "axios";
import { Sort } from "./Sort";



import { AiOutlineClose, AiOutlineSend } from "react-icons/ai";
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import notification from "../Toast";
import { removeCategoryItem, setFilterCategory } from "../../Redux/action";
import { useParams, useNavigate } from "react-router-dom";
import { searchItem } from "../../Redux/action";
import { SearchClient as TypesenseSearchClient } from "typesense";
// import { Link } from "react-router-dom";
import LoginModel from "../model/LoginModel";
import { useSearchParams } from "react-router-dom";
import { SearchPage } from "../../Pages/SearchPage";
import {Helmet, HelmetProvider} from "react-helmet-async";
import HandleGoogle from '../../Firebase/handleGoogleAuth';

import { LIstView_home } from "./LIstPage_home";
import { LIstView } from "./LIstPage";
import { GridPage } from "./GridPage";
import { Footer_search } from "./Footer_search";
import { LandingRight } from "../LandingRight";
import Slideshow_search from "./Crousel_search";
import Footer_options  from "./Footer_home";
import { Leftbar_home_search} from "./LeftBar_home_search";

import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  breakpoints: {
    sm: '420px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
  },
});

export const SearchComp = () => {
  const [searchParams] = useSearchParams();
  //const query = searchParams.get('q') || '';
  const { slug } = useParams();
  const query = slug.replace(/-/g, ' ');
  // const location = useLocation();
  const { categories } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  // const [list, setlist] = useState(true);
  const [viewMode, setViewMode] = useState('small grid'); 
  let [count, setCount] = useState(0);
  const [open, setopen] = useState(false);
  const [showMenu, setMenu] = useState(false);
  let { id } = useParams();
  const { searchReducer } = useSelector((store) => store.searchReducer);
  

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600); // 600px as a breakpoint for mobile view



  const [page, setPage] = useState(1);
  const perPage = 36; // Set the number of tools per page
  const [hasMore, setHasMore] = useState(true); // To track if there are more items to load


  const boxShadowColor = useColorModeValue('rgba(0, 0, 0, 0.2)', 'rgba(255, 255, 255, 0.2)');


  const handleActionButtonClick = async () => {
    if (!userData) {
      // If the user is not logged in, use HandleGoogle for login
      await HandleGoogle(dispatch, navigate, null, onOpen);
    } else {
      // If the user is already logged in, just open the modal
      onOpen();
    }
  };

  // const metaTitle = `${query} - AI Zones`;
  // document.title = metaTitle;
  // const metaDescription =`Explore ${query} tools. Your Ultimate AI Tools Database. Discover the perfect AI solutions for any project with our AI-driven search, tailored to match your specific needs`;

  const metaTitle = `Best AI Tools for ${query} in 2024 | AI Zones`;
  document.title = metaTitle;
  const metaDescription = `Explore the top AI tools for ${query}. Compare features, pricing, and find the perfect solution for your needs in 2024.`;


  // useEffect(() => {
  //   // This code runs when the component mounts
  //   if (document.activeElement instanceof HTMLElement) {
  //     document.activeElement.blur(); // Force the blur event on the active element
  //   }
  // }, []);

  useEffect(() => {
    // Check if the screen width is less than or equal to 700px
    const handleResize = () => {
      if (window.innerWidth <= 900) {
        setViewMode('list'); // Set to 'list' view on mobile devices
      } else {
        setViewMode('small grid'); // Set to 'small grid' view on larger devices
      }
    };

    // Call the function on component mount
    handleResize();

    // Optional: Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ------------------------------
  const userData = useSelector((state) => state.userReducer.loginData);
  const { searchItems } = useSelector((state) => state.searchReducer);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteLoading, setDeleteLoading] = useState(false);
  let [data, setData] = useState([]);
  // let [page, setPage] = useState(1);
  const [cat, setcatlist] = useState([]);
  // const [Category, setcat] = useState([]);
  // //console.log('Category: ', Category);
  const [message, setMessage] = useState("");
  const [pageName, setPageName] = useState("");
  const [FilterLoader, setFilterLoader] = useState(false);
  const [sort, setSort] = useState("");
  const [sortLoader, setSortLoader] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const categoriesFetchedRef = useRef(false);
  const categoriesDataRef = useRef(null);
  const borderColor=useColorModeValue("#e0e0e0", "#444");

  const [activeFilters, setActiveFilters] = useState({
    works_with: [],
    Pricing: [],
    others_features: []
  });

  const updateFilters = (filters) => {
    setActiveFilters(filters);
    // Here you should add logic to apply these filters to your search
    // This could be a call to an API, updating a Redux state, or triggering a local state update
    // Example: performSearchWithFilters(filters);
  };

 


  const [userinfo, setUserInfo] = useState({
    works_with: [],
    Pricing: [],
    loading: false,
    others_features: [],
  });

 
  const calculateFilterCount = () => {
    // Assuming each filter array contains the selected filter options
    return activeFilters.works_with.length + activeFilters.Pricing.length + activeFilters.others_features.length;
  };

  useEffect(() => {
    // Update count whenever activeFilters change
    const newCount = calculateFilterCount();
    setCount(newCount);
  }, [activeFilters]);

  const handleSortSelection = (event) => {
    const selectedSort = event.target.value;
    setSort(selectedSort); // Update the sort state
    setPageName('sort');
    setSortLoader(true);
  };


  const generateFilterString = (filters) => {
    const filterParts = [];
  
    if (filters.works_with.length > 0) {
      filterParts.push(`works_with:=[${filters.works_with.map(item => `'${item}'`).join(", ")}]`);
    }
  
    if (filters.Pricing.length > 0) {
      // Using ':=' for exact string match
      filterParts.push(`Pricing:=[${filters.Pricing.map(item => `'${item}'`).join(", ")}]`);
    }
  
    if (filters.others_features.length > 0) {
      filterParts.push(`others_features:=[${filters.others_features.map(item => `'${item}'`).join(", ")}]`);
    }
  
    return filterParts.join(" && ");
  };


  const [showInitialLoader, setShowInitialLoader] = useState(false);
  

const fetchTools = (currentPage, resetData = false, isInitialLoad = false) => {
  if (isInitialLoad) {
    setShowInitialLoader(true); // Show loader only for initial load
} else {
    setShowLoader(true); // This might be for infinite scroll or subsequent fetches
} 
    const client = new TypesenseSearchClient({
        nodes: [{
          host: "owx5ejkh9bitavyfp-1.a1.typesense.net",
          port: "443",
          protocol: "https",
        }],
        apiKey: "1xXwPtmFGeihv2Q9zTzD9p5MsVsvi4E1",
         connectionTimeoutSeconds: 2,
      });

    
          const searchParameters = {
            q: query,
            query_by: 'embedding,Title',
            facet_by: 'works_with,Pricing,others_features',
            // limit: 50,
            page: currentPage,
            // limit_hits: 100,
            per_page: perPage,
            filter_by: generateFilterString(activeFilters), 
            sort_by: sort === "likes:desc" ? 'likes:desc' : '_text_match:desc,paid:desc,weeklyScore:desc',
          };
      
         
          client.collections("Tools")
          .documents()
          .search(searchParameters)
          .then((result) => {
            if (resetData || currentPage === 1) {
              setData(result.hits.map(hit => hit.document));
            } else {
              setData(prevData => [...prevData, ...result.hits.map(hit => hit.document)]);
            }
            setHasMore(result.hits.length === perPage);
            if (isInitialLoad) {
              setShowInitialLoader(false); // Hide the initial loader after fetching
          } else {
              setShowLoader(false); // Hide the loader for infinite scroll or subsequent fetches
          }
      

             // Hide keyboard after search
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      })
      .catch((error) => {
        console.error("Error during Typesense search:", error);
        if (isInitialLoad) {
            setShowInitialLoader(false); // Also hide the initial loader in case of an error
        } else {
            setShowLoader(false); // Also hide the loader for infinite scroll or subsequent fetches in case of an error
        }
    });
};
    
    // useEffect(() => {
    //   fetchTools(page);
    // }, [query, page]);
    
// Handle new searches
useEffect(() => {
  setPage(1); // Reset to the first page
  fetchTools(1, true, true); // Fetch the first page of results for the new query with resetData = true
}, [query]); // Dependency on query ensures this runs on new search inputs

// Handle pagination changes for infinite scroll
useEffect(() => {
  if (page > 1) {
      fetchTools(page, false, false); // Fetch next page of results without resetting data
  }
}, [page]); // This effect is only for handling pagination changes

  useEffect(() => {
    setPage(1);
    fetchTools(1, true);
  }, [activeFilters, sort]);

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || !hasMore) {
      return;
    }
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  

  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.pathname !== "/search") {
      dispatch(searchItem({}))
      // searchItems = {};

    }
  }, []);

  

  const infinitScroll = async () => {
    try {
      
      if (
        (window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight) && window.location.pathname 
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", infinitScroll);
    return () => window.removeEventListener("scroll", infinitScroll);
  }, []);

  const handleCat = (el, i) => {
    // setcat((pre) => [...pre, el]);
    dispatch(setFilterCategory(el));

    setPageName("filter");
    setFilterLoader((prev) => !prev);
  };

  const removeItem = (el, i) => {
    //console.log("i: ", i);
    // Category.splice(i, 1);
    dispatch(removeCategoryItem(i));
    setPageName("filter");
    // setFilterLoader((prev) => !prev);
  };

  const sendMessage = () => {
    const payload = {
      message: message,
    };
    const token = userData.data;
    axios
      .post(`${process.env.REACT_APP_API}/message/add`, payload, {
        headers: { token },
      })
      .then((res) => {
        notification("success", res.data.msg);
        onClose();
      });
  };

  const setcat = (item) => {
    dispatch(setFilterCategory(item));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth", // Use 'auto' for instant scrolling, or 'smooth' for smooth scrolling
    });
  };
  const side = document.getElementsByClassName('sidebar');
  const sidebarWidth =  side[0]?.offsetWidth;



//   useEffect(()=> {
//     window.location.pathname === "/search" && setopen(false)
// //  setopen(true)

//   })

  const navigate = useNavigate();

  const reload = ()=> {
    navigate("/")
    window.location.reload()
  }

  let sorted = [
    // ...firstThreeFeatured,
    // ...remainingFeatured,
    ...data,
  ];

  const bg=useColorModeValue("#ffffff", "#222222");
  const textcolor=useColorModeValue("#444444", "#cccccc");
  const color = useColorModeValue("gray.600", "gray.400");

 
  const filtercolor=useColorModeValue("white", "#464444");
  const categorycolor=useColorModeValue("#ffffff", "#222222");
  const categorylistcolor=useColorModeValue("white", "#222222");


  // const [searchParams] = useSearchParams();
  // const query = searchParams.get('q')||'';

  const canonicalUrl = `${process.env.REACT_APP_PUBLIC_URL}/search/${slug}`;

  return (
    <HelmetProvider>
    <Helmet>
    <title>{metaTitle}</title>
    <meta name="description" content={metaDescription} />    
     <link rel="canonical" href={canonicalUrl} />


     <meta property="og:type" content="website" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={metaTitle} />            
        <meta property="og:description" content={metaDescription} />
        <meta property="og:site_name" content="AI Zones" />
        <meta property="og:image" content= 'https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png' />
     

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={canonicalUrl} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content="https://ai-zones.b-cdn.net/Website-utilities%20/AI-Zones_Cover.png" />

  </Helmet>    
  <>
    <Box>
         <FilterSearch
      open={open}
      setCount={setCount} // Pass necessary props
      setUserInfo={setUserInfo}
      userinfo={userinfo}
      updateFilters={updateFilters}
      setPageName={setPageName}
      setFilterLoader={setFilterLoader}
    />

      <Box
        hidden={id}
        // bg={useColorModeValue("#ffffff", "#222222")}
        // bgGradient={useColorModeValue(
        //   "linear(to right,  #f6f6f6, #f6f6f6 )", // light mode complementary gradient
        //   "linear(to right, #222222, #303030)" // dark mode grey gradient
        // )}
        className={style.subnav}
        fontFamily="'Lato', Arial, Helvetica, sans-serif"
        // borderBottom="1px"
        // borderColor={useColorModeValue("#cfd8dc", "#444")}
      >
        
        <Flex justifyContent="space-between" className={style.filterbutton} alignItems="center" >
          <Flex alignItems="center" gap="20px"  >
            <Flex
            display={{ base: "none", sm: "Flex" }}           
              position="relative"
              fontWeight="400"
              alignItems="center"
              // visibility={window.location.pathname !== "/search" ? "visible" : "hidden"}
              // justifyContent={open ? "space-between" : "center"}
              // w={open ? "142px" : ""}
              ml="15px"
              h="100%"
              borderRadius="4px"
              borderWidth="1px"
              padding={'5px'}
              backgroundColor={bg}
                          >
              <Box >
              <TbAdjustmentsHorizontal size={20} onClick={() => setopen(!open)}/>
              {count > 0 && (
             <Text
             onClick={() => setopen(!open)}
              position="absolute"
              top="-9px" // Adjust these values as needed
              right="-7px"
              fontSize="10px"
              bg="#3B89B6"
              color="white"
              borderRadius="100%"
              px="4px" // Padding for the circle shape
              textAlign="center"
            >
            {count}
          </Text>
        )}
              </Box>
              {open ? (
                <Text
                  onClick={() => setopen(!open)}
                  cursor="pointer"
                  fontSize="14px"
                  lineHeight="24px"
                  fontFamily="'Lato', Arial, Helvetica, sans-serif"
                  fontWeight="600"
                  letterSpacing="2.59px"
                  textTransform="uppercase"
                  marginLeft="2px"
                  padding="0 5px"                
                  
                >
                  Filters
                </Text>
            ) : (
                ""
              )}
              {(open && count > 0) ? (
                <Text
                  mb="10px"
                  w="15px"
                  h="15px"
                  alignItems="center"
                  textAlign="center"
                  justifyContent="center"
                  fontSize="10px"
                  bg="#3B89B6"
                  color="white"
                  borderRadius="100%"
                  position="absolute"
                  right="-2"
                  top="-2"
                >
                  {count}
                </Text>
              ) : (
                ""
              )}
            </Flex>

            <Box ml={window.innerWidth <= 450 ? "10px" : (open ? "23px" : "44px")}>
              <Menu bg={useColorModeValue("white", "#464444")}>
              {window.location.pathname.startsWith("/search/") && (
        
              <IconButton
              boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.1)"
              aria-label="Home"
             icon={<FaHome />}
            // isRound
            onClick={reload} // Added onClick event here
             />
            )}
 <MenuButton
                  px={3}
                  py="3px"
                  borderRadius="4px"
                  borderWidth="1px"
                  _hover={{ bg: "" }}
                  fontSize="14px"
                  lineHeight="24px"
                  fontWeight="400"
                  visibility={window.location.pathname.startsWith("/search") ? "hidden" : "visible"}
                  // onMouseEnter={!showMenu}
                  // onMouseLeave={onClose}
                  // onClick={handleCategoryClick}
                  bg={categorycolor}
                  boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.1)"
                >
            
                  <Flex alignItems="center" gap="5px">
                    <MdOutlineCategory /> Category <ChevronDownIcon />
                  </Flex>

                </MenuButton>
               
                <MenuList
                  bg={categorylistcolor}
                  overflow="auto"
                  whiteSpace="nowrap"
                  className={style.menulist}
                  width={"calc(100vw / 1.2) !important"} 
                  textColor={textcolor}              
                 
                >
                  {/* {isFetchingCategories && (
                  <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                 size='xl'

                  />
                )} */}
                  
                  <Flex fontSize="13px" gap="10" p="15px"
                  style={{
                  }}
                  
                  >
                    {cat?.map((el, i) => (

                      
                      <Box
                        key={i}
                        style={{
                          color: i % 2 == 0 ? "rgb(175 155 155 / 89%)" : "rgb(191 178 178)",                          
                          background: i % 2 == 0 ? "transparent" : "rgb(89 76 76 / 10%)",                          
                          width: "700px",
                          padding: "2%",
                         
                        }}
                      >
                         <Text
                          bg=""
                          mt="5px"
                          mb="5px"
                          fontSize="17px"
                          fontWeight="700"
                          lineHeight="16px"
                         color={textcolor}
                        >
                          {el.Title}
                        </Text>
                        <Flex flexDirection="column">
                          {el.Category.map((e, ind) => (
                            <Text
                              key={ind}
                              cursor="pointer"
                              fontSize="12px"
                              fontWeight="400"
                              lineHeight="31.5px"
                              color={textcolor}
                              _hover={{
                                fontSize: "15px",
                                color: "#5e6b9b"
                              }}
                              onClick={() => {
                                handleCat(e, ind);
                                // onClose();
                              }}
                            >
                              {e}
                            </Text>
                          ))}
                        </Flex>
                      </Box>
                    ))}
                  </Flex>
                </MenuList>
              </Menu>
            </Box>

            <Box   className={style.xscrollbox}>
              <Flex
                position="relative"
                alignItems="center"
                gap="15px"
                h="100%"               
                overflowX={"scroll"}
                whiteSpace={"nowrap"}
                className={style.xscroll}
              >
                {[...new Set(categories)]?.map((el, i) => (
                  <Flex
                    key={i}
                    px={2}
                    py="2.5px"
                    height="fit-content"
                    borderRadius="10px"
                    borderWidth="1px"
                    alignItems="center"
                    justifyContent="space-between"
                    textAlign="center"
                    gap="5px"
                    bg={bg}
                                        position="relative"  
                    display={{ base: "none", md: "block" }}
                  >
                    <Text fontStyle="12px" fontWeight="400" lineHeight="24px">
                      {" "}
                      {el}{" "}
                    </Text>{" "}
                    <AiOutlineClose
                    
                      size={10}
                      cursor="pointer"
                      onClick={() => removeItem(el, i)}
                    />{" "}
                  </Flex>
                ))}
              </Flex>
            </Box>
          </Flex>

          <Flex className={style.margin} alignItems="center">

             {/* <Button bg="" cursor="pointer" onClick={() => setlist(true)}>
              <ImList2 fontWeight="300" />
            </Button> */}
            {/* .smallbox */}
            {/* <Button bg="" cursor="pointer" onClick={() => setlist(false)}>
              <BsGrid />
            </Button> */}
   <Box bg={bg}  size="xs"  borderColor={borderColor} borderRadius="5px" boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.1)"  >
            <Flex align="center"  > 
  <Text  display={{ base: "none", md: "block" }} px={2}>Views:</Text>

  <Button  display={{ base: "none", md: "block" }} bg="" cursor="pointer" onClick={() => setViewMode('small grid')}>
    {/* Replace with an appropriate icon for "xy" view */}
    <CgMenuGridO size="22px" />   
  </Button>

  <Button display={{ base: "none", md: "block" }} bg="" cursor="pointer" onClick={() => setViewMode('list')}>
    <ImList2 fontWeight="300" />   
  </Button>

  <Button  display={{ base: "none", md: "block" }} bg="" cursor="pointer" onClick={() => setViewMode('grid')}>
    <BsGrid />    
  </Button>
  </Flex>
  </Box>
  
            {/* --------------------------------------- */}
                <Flex px={5}>
            <Select
           onChange={handleSortSelection}
           defaultValue=""
           size="sm"
           
           bg={useColorModeValue("#F6f6F6F", "#2D3748")}
          //  borderColor={useColorModeValue("#CBD5E0", "#CBD5E0")}
           color={useColorModeValue("#2D3748", "#FFFFFF")}
           borderRadius="10px"  
           boxShadow="0 2px 4px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.1)" // This line adds the shadow
                  >
         <option value="">Default</option>
         <option value="likes:desc">Most Liked</option>
         
          </Select>
          </Flex>

{/* <Sort
              setSort={setSort}
              setPageName={setPageName}
              setSortLoader={setSortLoader}
            /> */}

            {/* ----------------------------------------------- */}
          </Flex>
        </Flex>
      </Box>

      {/* <Flex
  mt={{ base: "5px", md: "60px" }}
  align="center"
  justify="center"
  height="100vh" // This will make sure it takes full viewport height
>
  <Text
    fontSize="2xl" // Adjust the font size as needed
    fontWeight="bold"
    textAlign="center"
  >
    Under maintenance, come back later.
  </Text>
</Flex> */}


<Flex mt= {{ base: "5px", md: "60px" }} >
        {/* <Box
          className={style.smallbox}
          alignItems="center"
          display={{ base: "none", md: "block" }}
        >
          <Button bg="" cursor="pointer" onClick={() => setlist(true)}>
            <ImList2 fontWeight="300" />
          </Button>

          <Button bg="" cursor="pointer" onClick={() => setlist(false)}>
            <BsGrid />
          </Button>
        </Box> */}
        {<Stack w="100%" position="relative" mt={window.innerWidth < 600 && "70px"}>
      <Flex
        color={useColorModeValue("#333333", "#CCCCCC")}
        className={(open) ? style2.opengap : style2.closegap}
      >
        <Stack w="fit-content">
          {/* {location.pathname !== '/search' && (  */}
          {/* <FilterSearch
            open={open}           
            userinfo={userinfo}
            setUserInfo={setUserInfo}
            setPageName={setPageName}
            setFilterLoader={setFilterLoader}
            setCount={setCount}
          /> */}
          {/* )}  */}
        </Stack>  

        
  {showInitialLoader ? (
  <Flex position="fixed" top="0" left="0" right="0" bottom="0" align="center" justify="center" zIndex="9999">
  <Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl'/>
</Flex>
      ) : ( 

        <Stack 
        className={ (open ? style2.openslide : style2.closeslide)} 
        w="100%">
          <Box w="100%">
            <Flex className={open ? style2.opengap : style2.closegap}>
              <Stack className={style2.landingbox}>
                <Box w="100%">
                  <Slideshow_search searchName={query} />

                  <Leftbar_home_search
                      open={open}
                      setCount={setCount} // Pass necessary props
                      setUserInfo={setUserInfo}
                      userinfo={userinfo}
                      updateFilters={updateFilters}
                      setPageName={setPageName}
                      setFilterLoader={setFilterLoader}
                      />

                   <Divider my={7} />

                  <Box className={style2.smallScreenList}>
                  {
                  viewMode === 'small grid' ? (
                 <LIstView_home
                  data={sorted}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                  showLoader={showLoader}
                  setDeleteLoading={setDeleteLoading}
                  userinfo={userinfo}
                  setcat={setcat}
                />
                ) : viewMode === 'grid' ? (
                  <GridPage
                  data={sorted}
                  setPageName={setPageName}
                  setFilterLoader={setFilterLoader}
                  showLoader={showLoader}
                  setDeleteLoading={setDeleteLoading}
                  userinfo={userinfo}
                  setcat={setcat}
                />                
              
              ) : (
               
                  <LIstView
                   data={sorted}
                   setPageName={setPageName}
                   setFilterLoader={setFilterLoader}
                   showLoader={showLoader}
                   setDeleteLoading={setDeleteLoading}
                   userinfo={userinfo}
                   setcat={setcat}
                 />
                 )
            }
                  </Box>
                </Box>
              </Stack>

              <Stack>
                   <Box display="block" css={{ '@media(max-width: 1500px)': { display: 'none' } }}>
    <LandingRight />
  </Box>
              </Stack>
            </Flex>
          </Box>
          
        </Stack>
         )}
      </Flex>

      
      {
      (window.location.pathname)  && <Footer_search
        count={count}
        setSort={setSort}
        setSortLoader={setSortLoader}
        setUserInfo={setUserInfo}
        setPageName={setPageName}
        setFilterLoader={setFilterLoader}
        cat={cat}
        setcat={setcat}
        setCount={setCount}
        updateFilters={updateFilters}
      />
      }
    </Stack>}
    </Flex>



      {/* {loginModelOpen? <LoginModel onCloseOuter={handleClose}/> : null} */}
{/* //////////////////////////////////// */}
      <Box
        cursor="pointer"
        onClick={() =>{
          if(!userData){
            handleActionButtonClick();
            return;
          }
          onOpen();
        }}
        // onClick={handleActionButtonClick}
        p="10px"
        position="fixed"
        bottom="8%"
        right="2%"       
        zIndex="200"
        bg="black"
        borderRadius="10px"
        boxShadow={`0px 4px 10px ${boxShadowColor}`} 
      >
        <BsChatText color="white" size={25} />
      </Box>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent w="350px" color="#444" bg="#D9D9D9" borderRadius="0px">
          <ModalHeader
            bg="#3B89B6"
            color="white"
            fontSize="16px"
            lineHeight="24px"
            fontWeight="700"
          >
            How can we help ?
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody fontSize="13px" fontWeight="400" lineHeight="16px">
            <Flex
              h="390px"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
              <Text fontSize="13px" lineHeight="24px" fontWeight="400">
                {" "}
                Welcome to our Support Page! We value your feedback and are here
                to assist you. If you have any questions, concerns, or
                suggestions regarding our platform or experience any issues, we
                encourage you to reach out to us
              </Text>

              <Text fontSize="13px" marginTop={"30px"} lineHeight="24px" fontWeight="400">
                {" "}
                Contact: hello@aizones.io
              </Text>
              </Box>

              <InputGroup >
                <Input
                  onChange={(e) => setMessage(e.target.value)}
                  border="1px solid #000"
                  borderRadius="5px"
                  mt={15}
                  _placeholder={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "#000",
                  }}
                  placeholder="send a message"
                  onKeyDown={(e) =>{
                    
                    if (e.key === "Enter"){
                      sendMessage();
                    }
                  }}
                />
                
              </InputGroup>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
   
    <Footer_options/>
    
    </>
    </HelmetProvider>
  );
};
