import React, { useEffect, useState, useRef} from "react";
import style from "../../Style/Grid.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { LockIcon, UnlockIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";

import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  CloseButton,
  List, ListItem, Switch, FormLabel, useColorMode
} from "@chakra-ui/react";
import { setMyspaceName } from "../../Redux/action";
import { CheckIcon } from "@chakra-ui/icons";
import { HiOutlineBookmark } from "react-icons/hi";
import notification from "../Toast";
import LoginModel from "../model/LoginModel";
import { blue } from "@mui/material/colors";
import HandleGoogle from '../../Firebase/handleGoogleAuth';


const Save = ({ id, el, width="" }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputName, setInputName] = useState(false);
  const [newLibrary, setNewLibrary] = useState("");
  const [tagline, setTagline] = useState("");
  const [loading, setloading] = useState(false);
  const [allLibrary, setAllLibrary] = useState([]);
  const userData = useSelector((state) => state.userReducer.loginData);
  const collectionData = useSelector((state) => state.spaceReducer.collectionItems);
  const [reload, setreload] = useState(true);
  // const [loginModelOpen, setloginModelOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [allLibrary, setAllLibrary] = useState([]);

  const mountedRef = useRef(true);
  const navigate = useNavigate();
  
  

  const [showCreateView, setShowCreateView] = useState(false);
  const [isPublic, setIsPublic] = useState(true); // State to manage the collection privacy setting

  const { colorMode } = useColorMode();
  const hoverBgColor = colorMode === "light" ? "gray.200" : "gray.600";


  const handleOpen = async () => {
    if (!userData) {
      // If the user is not logged in, use HandleGoogle for login
      await HandleGoogle(dispatch, navigate, null, () => onOpen());
    } else {
      // If the user is already logged in, just open the modal
      onOpen();
    }
  };


  
  // const handleOpen = () => {
  //   if (!userData) {
  //     setloginModelOpen(true);
  //   } else {
  //     // Call getData when the modal is opened
  //     if (allLibrary.length < 1) {
  //       getData();
  //     }
  //     onOpen();
  //   }
  // };

  

  const handleClose = () =>{
    // setloginModelOpen(false)
  }
  const dispatch = useDispatch();
  const gdataArray = [];
  let token = userData.data || false;

  const handleCreate = async (e) => {
    e.preventDefault();
    setloading(true); // Start loading

    onClose(); // Close the modal immediately when the button is clicked


    try {
      let token = userData.data;
      const payload = {
        space_name: newLibrary,
        description: tagline,
        tools: [{ toolID: el._id, logoURL: el?.Logo }], // Updated structure
        status: isPublic ? "Public" : "Private",
        videoID : el._id,       
      };

      //console.log(payload);

      const url = `${process.env.REACT_APP_API}/space/create`;
      const { data: res } = await axios.post(url, payload, {
        headers: { token },
      });
      setreload(!reload);
      setNewLibrary("");
      setTagline("");
      setIsPublic(false); // Reset the privacy toggle if needed
      notification("success", res.msg);
      setInputName(false);
    } catch (err) {
      notification("error", err.response.data.errors);
    } finally {
      setloading(false); // Stop loading regardless of success or failure
    }
  };

  // const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const getData = async () => {
    if (!mountedRef.current) return; // Guard clause to prevent state update if the component is unmounted.
    
    setloading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API}/space/getForSave`, {
        headers: { token },
      });
      if (mountedRef.current) { // Check again after the async call.
        setAllLibrary(res.data.data);
        dispatch(setMyspaceName(res.data.data));
        // No need for console.log in production, consider removing it
      }
    } catch (error) {
      notification("error", error.response.data.errors);
    } finally {
      if (mountedRef.current) {
        setloading(false);
      }
    }
  };

  useEffect(() => {
    // Cleanup function to set the mounted ref to false when the component unmounts
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (isOpen && userData) {
      getData();
    }
    // Ensure the effect runs only when the modal is opened and there's userData
  }, [isOpen, userData]); 

  
  // const handleOpen = () => {
  //   if (!userData) {
  //     setloginModelOpen(true);
  //   } else {
  //     // Call getData when the modal is opened
  //     if (allLibrary.length < 1) {
  //       getData();
  //     }
  //     onOpen();
  //   }
  // };

    const handleLibrarySubmit = async (lid) => {
      if (isSubmitting) {
        return; // Prevent further submissions if one is already in progress
      }

      setIsSubmitting(true); // Start submission
      onClose(); // Close the modal immediately

    try {
      let token = userData.data;

      const payload = {
        videoID: el._id,
        spaceID: lid,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API}/myspace/add`,
        payload,
        { headers: { token } }
      );
      notification("success", res.data.msg);

      const spacePayload = {
        tool: [{ toolID: el._id, logoURL: el?.Logo }] // Updated structure
    };
    
      const url = `${process.env.REACT_APP_API}/space/update/${lid}`;
      const { data: spaceRes } = await axios.patch(url, spacePayload, {
        headers: { token },
      });

      setreload(!reload);
      setNewLibrary("");
      setTagline("");
      // onClose(); // Close the modal after successful addition
    } catch (err) {
      if (err.response && err.response.status === 409) {
        // Specific handling for the case when the tool is already added
        notification("warning", err.response.data.msg);
      } else {
        // Generic error handling
        notification("error", err.response.data.errors);
      }
    } finally {
      setIsSubmitting(false); // Reset submission state
    }
  };
useEffect(() => {
  setAllLibrary(collectionData ?? []);
}, [collectionData])



  const toggleCollectionPrivacy = () => {
    setIsPublic((prev) => !prev);
  };

  const handleBackClick = () => {
    setShowCreateView(false);
    setNewLibrary("");
    setTagline("");
  };

  return (
    <>
       {/* {loginModelOpen? <LoginModel onCloseOuter={handleClose}/> : null} */}
<Button
  onClick={handleOpen}
  px={{ base: 3, md: 6 }} // Padding X: 3 on base (mobile), 'auto' on md (medium screens) and up
  py={{ base: "11px", md: "16px" }} // Padding Y: '11px' on base (mobile), 'auto' on md (medium screens) and up
  border="1px"
  borderColor={useColorModeValue("#e0e0e0", "#444")}
  borderRadius="5px"
  fontSize="14px"
  fontWeight="400"
  gap="5px"
  textAlign="center"
  h={{ base: "auto", md: "29.68px" }} // Height: 'auto' on base (mobile), '29.68px' on md (medium screens) and up
  background="transparent"
  width={width}
>
  <HiOutlineBookmark />
  <Text display={{ base: "none", md: "block" }}>Collect</Text> {/* Only display on md screens and up */}
</Button>
      <Modal
        isCentered
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent
         color={colorMode === "light" ? "#444" : "white"} // Adjust text color based on color mode
         bg={colorMode === "light" ? "#f2f2f2" : "gray.600"} // Adjust background color based on color mode
         borderRadius="20px"
         w="fit-content"
         position="relative"
          >
         <CloseButton
          position="absolute"
         right="25px"
         top="20px"
         onClick={onClose}
        />
          <ModalBody>
          
            <Box padding="20px">
              <Flex alignItems="center" gap="15px" m="10px" ml="0px">
                <Avatar src={el?.Logo} />
                <Text fontSize="16px" lineHeight="24px" fontWeight="700">
                  {el?.Title}
                </Text>
              </Flex>

              {!showCreateView ? (
            <>

              <Text
                mt="15px"
                fontSize="24px"
                lineHeight="32px"
                fontWeight="400"
                // padding="5px"            
                
              >
                Save to
              </Text>

             

              <Box h="200px" overflow="auto" fontWeight="400">
  {loading ? (
    <Text>Loading...</Text>
  ) : (
    allLibrary?.map((ele, i) => (
      <Box
        cursor="pointer"
        onClick={() => handleLibrarySubmit(ele?._id)}
        mt="15px"
        key={i}
        value={ele?._id}
      >
        <Flex align="center" w={{ base: "250px", md: "320px" }} justify="space-between">
          <Box>
            <Text fontSize="16px" lineHeight="24px">
              {ele?.space_name}
            </Text>
            <Text fontSize="14px" lineHeight="24px">
              {ele?.description}
            </Text>
          </Box>
          {/* Display lock or unlock icon with hover text based on the status */}
          <Tooltip label={ele?.status === "Private" ? "Private" : "Public"} fontSize="md">
            {ele?.status === "Private" ? (
              <LockIcon boxSize={5} color="red.500" />
            ) : (
              <UnlockIcon boxSize={5} color="green.500" />
            )}
          </Tooltip>
        </Flex>
        {i < allLibrary.length - 1 && <Divider />}
      </Box>
    ))
  )}
</Box>
    <Box
    borderTop="1px"
    borderColor="transparent" // Set the border color to transparent
    // marginTop={2}
    marginBottom={!inputName ? 1 : 3}
  ></Box>

  {/* Create new collection button */}
  <Button
    onClick={() => setShowCreateView(true)}
    variant="Outline" // or any other variant that fits your design
    mt="2" // Adjust margin as needed
    
  >
    + Create new collection
  </Button>
                </>
                ) : (
                  <Box>
                    <Button onClick={handleBackClick}>Back</Button>
                    <Input
                      mt="4"
                      type="text"
                      placeholder="Collection Title"
                      value={newLibrary}
                      onChange={(e) => setNewLibrary(e.target.value)}
                    />
                    <Textarea
                      mt="4"
                      placeholder="Collection Description"
                      value={tagline}
                      onChange={(e) => setTagline(e.target.value)}
                    />
                    <Flex align="center" mt="4">
                      <Switch
                        id="public-switch"
                        isChecked={isPublic}
                        onChange={toggleCollectionPrivacy}
                      />
                      <FormLabel htmlFor="public-switch" ml="2">
                        {isPublic ? "Public" : "Private"}
                      </FormLabel>
                    </Flex>
                    <Button
                      mt="4"
                      colorScheme="blue"
                      onClick={handleCreate}
                      isLoading={loading}
                      loadingText="Creating"
                    >
                      Create Collection
                    </Button>
                  </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Save;
